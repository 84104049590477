import { Link } from 'react-router-dom';
import { IFarmResource } from '../../../entities/farms.entities';
import NameWithPercent from '../name-with-percent/NameWithPercent';
import Tooltip from '../tooltip/Tooltip';
import { Image, Table } from 'antd';
import farm_image from '../../../images/farm.png';
import useMenuHandler from './useMenuHandler';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from '../../../store/auth/auth.selector';
import FarmLeaseButton from '../../farm-util/FarmLeaseButton';
import { sendSingleRequest } from '../../../apis';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { isSpinner, showFeedback } from '../../../store/ui/ui.actions';
import LinesTable from './LinesTable';
import CaretDownIcon from '../CaretDownIcon';
import { selectLang } from '../../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../../lib/lang.helper';
import ModalComponent from '../modal/Modal';
import DotsMenu from '../dropdown-menu/DotsMenu';
import './styles.scss';
import FarmStatusButton from '../../farm-util/FarmStatusButton';

interface Props {
  farmsData: IFarmResource[];
}

const FarmsTable = ({ farmsData }: Props) => {
  const dispatch = useDispatch<any>();
  const { redirectToFarmVisualLine, redirectToEditFarm, redirectToFarm } =
    useMenuHandler();
  const profile = useSelector(selectProfile);
  const lang = useSelector(selectLang);
  const [visFarm, setVisFarm] = useState<IFarmResource>();

  const deleteFarm = async (farm: IFarmResource) => {
    if (!window.confirm('Are you sure you want to delete this farm?')) return;
    dispatch(isSpinner(true));
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/farms/${farm.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Farm deleted successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, response.data?.message ?? 'Unknown error'),
        }),
      );
    }
    dispatch(isSpinner(false));
  };

  const columns = useMemo(() => {
    let res = [
      {
        title: translate(lang, 'Farm'),
        key: 'farm',
        className: 'drag-farm',
        render: (farm: IFarmResource) => (
          <div 
            className={farm?.is_flupsy ? 'flupsy-farm-title' : ''}
          >
            <p>{farm.name}</p>
            
            <div>
              <span className='pr-6'>{farm.farm_number}</span>
            </div>
          </div>
        ),
      },
      {
        title: translate(lang, 'Crop type'),
        key: 'type',
        render: (farm: IFarmResource) => {
          return <span>{farm.type}</span>;
        },
      },
      {
        title: '',
        key: 'area',
      },
      {
        title: translate(lang, 'Area'),
        key: 'area',
        className: 'drag-area',
        render: (farm: IFarmResource) => {
          return <span>{farm.area}ha</span>;
        },
      },
      {
        title: translate(lang, 'Owner'),
        key: 'owners',
        render: (farm: IFarmResource) => {
          const tooltipItems = (farm.owners ?? []).map((owner, i) => ({
            title: `${owner?.title} - ${owner?.percent}%`,
            id: i,
          }));
          return (
            <Tooltip content={tooltipItems} position='bottom'>
              <div className='tooltip d-flex'>
                {(farm.owners ?? []).map(
                  ({ title, percent }: any, i: number) => (
                    <div key={i} className='table__owner'>
                      <NameWithPercent
                        title={title}
                        percent={percent}
                        index={i + 1}
                      />
                    </div>
                  ),
                )}
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: '',
        key: 'visual',
        width: '7%',
        render: (f: IFarmResource) => (
          <Link
            to={`/farms/visual-lines/${f.id}`}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              redirectToFarmVisualLine(f.id);
            }}
          >
            <Image src={farm_image} style={{ maxWidth: '30px' }} />
          </Link>
        ),
      },
    ];
    res.push({
      title: translate(lang, 'Area Closing'),
      key: 'lease',
      render: (x: IFarmResource) =>
        x.account_id ? <></> : <FarmStatusButton farm={x} />,
    });
    res.push({
      title: '',
      key: 'more',
      render: (d: IFarmResource) => (
        <div>
          {!d.account_id && !!profile?.edit_permission && (
            <DotsMenu
              items={[
                {
                  label: translate(lang, 'Edit'),
                  onClick: () => redirectToEditFarm(d.id.toString()),
                },
                {
                  label: translate(lang, 'Delete'),
                  onClick: () => deleteFarm(d),
                },
                {
                  label: translate(lang, 'Share'),
                  onClick: () => setVisFarm(d),
                },
              ]}
            />
          )}
        </div>
      ),
    });
    return res;
  }, [redirectToFarmVisualLine, profile]);

  return (
    <>
      <Table
        className={'table table--isFarms'}
        rowKey={'id'}
        pagination={false}
        columns={columns}
        dataSource={farmsData}
        onRow={dataRow => ({
          onClick: () => redirectToFarm(dataRow.id.toString()),
        })}
        expandable={{
          expandedRowRender: d => <LinesTable farmData={d} isChild={true} />,
          expandIcon: ({ onExpand, record }) => (
            <div
              className='pt-20 pb-20'
              onKeyDown={() => undefined}
              onClick={event => {
                event.stopPropagation();
                onExpand(record, event);
              }}
            >
              <CaretDownIcon />
            </div>
          ),
        }}
      />
      {visFarm && (
        <ModalComponent
          visible={true}
          onCancel={() => setVisFarm(undefined)}
          type='success'
          title={translate(lang, 'Share this farm with another account')}
          text={componentTranslate(
            lang,
            'Your farm ID is %s. Please make sure you share it with trusted person as they will gain access to your farm data.',
            <strong key={1}>{visFarm.id}</strong>,
          )}
          hideCancelBtn={true}
          onConfirm={() => setVisFarm(undefined)}
        />
      )}
    </>
  );
};

export default FarmsTable;
