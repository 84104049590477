import { useState } from 'react';
import { Modal, Table } from 'antd';
import { IFarmResource } from '../../entities/farms.entities';
import { Button, CloseIcon, Datepicker, Subtitle } from '../shared';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import moment from 'moment';
import { formatNumber } from '../../entities/util-functions';
import { sendSingleRequest } from '../../apis';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { loadFarmsData } from '../../store/farms/farms.actions';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

const columns = (lang: any) => [
  {
    key: 'created_at',
    dataIndex: 'created_at',
    title: translate(lang, 'Closed Date'),
    render: (x: any) => <span>{defaultDateFormat(x)}</span>
  },
  {
    key: 'due_date',
    dataIndex: 'due_date',
    title: translate(lang, 'Closed Until'),
    render: (x: any) => <span>{defaultDateFormat(x)}</span>
  },
  {
    key: 'more',
  },
];

interface Props {
  farm: IFarmResource;
  className?: string;
  onClose: () => void;
}

const FarmStatusModal = ({ farm, onClose, className }: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const feeList = farm.farm_status?.all ?? [].map(
    (x: any, i: number) => ({ id: i.toString(), ...x }),
  );

  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState(
    farm.farm_fee?.start_date
      ? farm.farm_fee?.start_date * 1000
      : moment().toDate().getTime(),
  );

  const updateDate = (date: number) => {
    const min = farm.farm_status?.due_date;
    if (min && date < min * 1000) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(
            lang,
            'Minimum allowed date is %s',
            defaultDateFormat(min),
          ),
        }),
      );
      return;
    }
    if (date < moment().add(1, 'days').toDate().getTime()) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(
            lang,
            'Minimum allowed date is %s',
            defaultDateFormat(moment().add(1, 'days').toDate().getTime()),
          ),
        }),
      );
      return;
    }
    setDate(date);
  };
  const closeModal = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
  };
  const afterClick = (res: any) => {
    setDisabled(false);
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Success'),
        }),
      );
      dispatch(loadFarmsData());
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, res.data?.message ?? 'Failed'),
        }),
      );
    }
    onClose();
  };
  const closeClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setDisabled(true);
    sendSingleRequest(
      {
        status: farm.farm_status?.status ? 'close' : 'extend',
        farm_id: farm.id,
        due_date: new Date(date).toISOString().slice(0, 19).replace("T", " "),
      },
      'POST',
      'api/farm/farm-status',
      true,
    ).then(res => {
      afterClick(res);
    });
  };

  const openClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setDisabled(true);
    sendSingleRequest(
      {
        status: 'open',
        farm_id: farm.id,
        due_date: new Date(date).toISOString().slice(0, 19).replace("T", " "),
      },
      'POST',
      'api/farm/farm-status',
      true,
    ).then(res => {
      afterClick(res);
    });
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      className={className}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <div
        className='wrap'
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className='d-flex align-items-center justify-content-center mb-16'>
          <Subtitle color='black-1' align='center' size={1} fontWeight={600}>
            {translate(lang, 'Farm %s status', farm.name)}
          </Subtitle>
        </div>
        <div className='mt-16 mb-16'>
          <Subtitle color='black-1' align='center' size={0} fontWeight={500}>
            {!!farm.farm_status?.status 
              ? translate(lang, '%s is open', farm.name)
              : translate(
                  lang,
                  '%s is closed at %s, for %s days.',
                  farm.name,
                  defaultDateFormat(farm.farm_status.created_at),
                  farm.farm_status.days_left,
                )}
          </Subtitle>
        </div>
        {farm.farm_status?.all && (
          <>
            <div className='mt-17'>
              <Table
                className='table'
                rowKey={'id'}
                pagination={false}
                columns={columns(lang)}
                dataSource={feeList}
              />
            </div>
          </>
        )}
        <div className='pb-24'>
          <Datepicker
            className='mb-17'
            defaultValue={date}
            label={translate(lang, 'Close untill')}
            onChange={e => e && updateDate(e.toDate().getTime())}
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-center align-items-center'>
        { farm.farm_status?.status == false &&
        <Button
          className='rsp-btn ml-17 mr-17'
          width='small'
          size={2}
          type='fill'
          color='green'
          onClick={openClick}
          disabled={disabled}
        >
          {translate(
            lang,
            'Open Farm',
          )}
        </Button>
        }
        <Button
            className='rsp-btn ml-17 mr-17'
            width='small'
            size={2}
            type='fill'
            color='red'
            onClick={closeClick}
            disabled={disabled}
          >
            {translate(lang, !farm.farm_status?.status ? 'Extend close period' : 'Close Farm')}
        </Button>
        <Button
          className='rsp-btn ml-17 mr-17'
          width='small'
          size={2}
          type='fill'
          color='blue'
          onClick={closeModal}
          disabled={disabled}
        >
          {translate(lang, 'Cancel')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default FarmStatusModal;
