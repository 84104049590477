import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { selectAccount } from '../../store/auth/auth.selector';
import { selectSizedOysters } from '../../store/utils/utils.selector';
import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { translate } from '../../lib/lang.helper';
import { Input } from '../shared';
import { formatNumber } from '../../entities/util-functions';
import { Table } from 'antd';
import { sendSingleRequest } from '../../apis';
import { updateAccountSetting } from '../../store/auth/auth.actions';
import { loadUtilsData } from '../../store/utils/utils.actions';
import {
  calcOnGrowWastePercent,
  calcOysterPercent,
} from '../../lib/farm.helpers';

interface IRow {
  id: string | number;
  name: string;
  value: number | undefined;
}

interface Props {
  lineID: number;
  totalAmount: number;
  probabilities?: Record<string, string>;
  amountType?: string;
}

export interface OysterProbRef {
  checkIsValid(): string | true;
  runConfirm(): Promise<string | true>;
}

const OysterLineProbTable = forwardRef<OysterProbRef, Props>(
  ({ lineID, totalAmount, probabilities, amountType = 'dz' }, ref) => {
    const dispatch = useDispatch<any>();
    const lang = useSelector(selectLang);
    const onGrowWaste = useSelector(selectAccount)?.oyster_crops;
    const oysterFields = useSelector(selectSizedOysters);

    const defaultValues = [
      {
        id: 'Grown ons',
        name: translate(lang, 'Grown ons'),
        value: calcOnGrowWastePercent(onGrowWaste, 'grow', lineID),
      },
      {
        id: 'Waste',
        name: translate(lang, 'Waste'),
        value: calcOnGrowWastePercent(onGrowWaste, 'waste', lineID),
      },
      ...oysterFields.map(x => ({
        id: x.id,
        name: x.name,
        value: calcOysterPercent(x, lineID),
      })),
    ];

    const [data, setData] = useState<IRow[]>(defaultValues);
    const percentSum = data.reduce((acc, x) => acc + (x.value || 0), 0);

    const updateField = (id: number | string, value: string) => {
      const tmp = [...data];
      const i = tmp.findIndex(x => x.id == id);
      tmp[i].value = value.length <= 0 ? undefined : Number(value);
      setData(tmp);
    };

    useEffect(() => {
      if (probabilities) {
        Object.keys(probabilities).map((value, key) => {
          updateField(value, probabilities[value]);
        })
      }
    }, [probabilities]);

    const columns: any = [
      {
        title: translate(lang, 'Name'),
        key: 'name',
        render: (x: IRow) => x.name,
        width: 180,
      },
      {
        title:
          translate(lang, 'Probability') +
          (percentSum === 100
            ? ''
            : ` (${Math.floor(percentSum).toFixed(1)} %)`),
        key: 'value',
        render: (x: IRow) => (
          <Input
            label=''
            placeholder={translate(lang, 'Probability')}
            type='number'
            value={x.value?.toString() ?? ''}
            onChange={e => updateField(x.id, e.target.value)}
            unit='%'
          />
        ),
        width: 220,
      },
      {
        title: translate(lang, 'Amount'),
        key: 'amount',
        render: (x: IRow) =>
          x.value === undefined || x.value === null ? (
            '-'
          ) : (
            <span>{`${formatNumber((x.value * totalAmount) / 100)} ${amountType}`}</span>
          ),
        align: 'left',
      },
    ];

    const canSkip = () => {
      if (data.every(x => !x.value)) return true;
      if (
        data.find(x => x.id === 'Grown ons')?.value !==
        defaultValues.find(x => x.id === 'Grown ons')?.value
      )
        return false;
      if (
        data.find(x => x.id === 'Waste')?.value !==
        defaultValues.find(x => x.id === 'Waste')?.value
      )
        return false;
      return oysterFields.every(x => {
        const nv = data.find(y => y.id === x.id);
        const ov = defaultValues.find(y => y.id === x.id);
        return nv?.value === ov?.value;
      });
    };
    const checkIsValid = () => {
      if (canSkip()) {
        return true;
      }
      if (data.some(x => x.value === undefined || x.value === null)) {
        return translate(lang, 'Please enter probability');
      }
      const sum = data.reduce((acc, x) => acc + (x.value || 0), 0);
      if (Math.abs(100 - sum) > 0.0001) {
        return translate(lang, 'All probability values should total to 100%');
      }
      return true;
    };

    const runConfirm = async () => {
      if (canSkip()) {
        return true;
      }
      const isValid = checkIsValid();
      if (isValid !== true) {
        return isValid;
      }
      const utils = oysterFields.map(x => {
        const d = data.find(y => y.id === x.id);
        let json: any = x.json;
        if (!json || !json.lines) {
          json = { lines: { [lineID]: d?.value } };
        } else {
          json.lines[lineID] = d?.value;
        }
        return { ...x, json };
      });
      const res = await sendSingleRequest(
        utils,
        'POST',
        'api/utils/bulk',
        true,
      );
      if (!res.status) {
        return res.data?.message ?? 'Server error';
      }
      let lines_grow_ons: any = onGrowWaste?.lines_grow_ons ?? {};
      lines_grow_ons[lineID] = data.find(x => x.id === 'Grown ons')?.value;
      let lines_waste: any = onGrowWaste?.lines_waste ?? {};
      lines_waste[lineID] = data.find(x => x.id === 'Waste')?.value;
      const oyster_crops = {
        ...onGrowWaste,
        lines_grow_ons,
        lines_waste,
      };
      const r = await dispatch(updateAccountSetting({ oyster_crops }));
      if (r !== true) {
        return r ?? 'Server error';
      }
      await dispatch(loadUtilsData());
      return true;
    };

    useImperativeHandle(ref, () => ({
      checkIsValid,
      runConfirm,
    }));

    return (
      <Table
        rowKey={'id'}
        className='table table--units table--small'
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    );
  },
);

export default OysterLineProbTable;
