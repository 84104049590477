import React, { useState, useRef, useEffect } from 'react';
import { translate } from '../../../lib/lang.helper';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../store/ui/ui.selector';
import {
    Dropdown,
    Input,
    CheckboxButton
} from '../../shared';
import {
    SelectIsMyFarm,
} from '../../../store/extra/extra.selector';

import OysterLineProbTable, {
    OysterProbRef,
} from '../../farm-util/OysterLineProbTable';
import {
    SelectAssessFields,
  } from '../../../store/extra/extra.selector';

import { ICustomValue } from '../../../entities/automation.entities';
import { Link } from 'react-router-dom';
import { IOysterLineResource } from '../../../entities/farms.entities';
import moment from 'moment';

interface Props {
  lineData: IOysterLineResource;
  data?: any;
  onlyView?: boolean;
  updateForm: (key: keyof IFormData, value: any) => void;
  type: string;
  formDataPassed:any;
  errorsData: any;
  availableDozens: number | null;
}


interface IFormData {
  season_name: string;
  spat_storage_id: number | null;
  spat_amount_dz: number | null;
  spat_amount_pcs: number | null;
  planned_date_seed: number;
  planned_date_harvest: number;
  spat_size: number;
  spat_size_max: number | null;
  basket_count: number | null;
  spacing: number | null;
  density: number | null;
  comment: string | null;
  direction: string | null;
  cost?: number | null;
  images: any[];
  parent_id?: number | null;
  estimated_amount?: number | null;
  estimated_dozens?: number | null;
  size_min: number | null;
  size_max: number | null;
  size_avg: number | null;
  ready_to_harvest?: boolean;
  measurement_units?: string;
  estimated_pcs?: number | null;
}

const defaultFormData: IFormData = {
    season_name: '',
    spat_storage_id: null,
    spat_amount_dz: null,
    spat_amount_pcs: null,
    planned_date_seed: moment().toDate().getTime(),
    planned_date_harvest: moment().toDate().getTime(),
    spat_size: 0,
    spat_size_max: null,
    basket_count: null,
    spacing: null,
    density: null,
    comment: null,
    direction: null,
    images: [],
    parent_id: null,
    estimated_amount: null,
    estimated_dozens: null,
    size_min: null,
    size_max: null,
    size_avg: null,
    ready_to_harvest: false,
    measurement_units: 'dz',
};



const InstantOysterAssesment = ({ updateForm, lineData, data, onlyView, type, formDataPassed, errorsData, availableDozens} : Props) => {
    const [formData, setFormData] = useState<IFormData>({
        ...defaultFormData,
        ...formDataPassed
    });
    const [errors, setErrors] = useState<{
        min: string | null;
        max: string | null;
        avg: string | null;
        }>({
        min: errorsData?.size_avg ?? null,
        max: errorsData?.size_max ?? null,
        avg: errorsData?.size_avg ?? null,
        });
    const lang = useSelector(selectLang);
    const isMyFarm = SelectIsMyFarm(lineData.farm_id);
    const tableRef = useRef<OysterProbRef>(null);
    const customAssesFields = SelectAssessFields(lineData.farm_id);
    const [customAssessValues, setCustomAsseesValues] = useState(
        customAssesFields.map(x => {
          const d =
            data && data.custom_values
              ? data.custom_values.find((y: ICustomValue) => y.field_id === x.id)
              : null;
          return {
            input_type: x.input_type,
            field_label: x.name,
            options: x.options
              ?.split(',')
              .map(t => ({ id: t, label: t, value: t })),
            field_id: x.id,
            field_val: d?.field_val ?? '',
            data_type: x.data_type,
            unit: x.unit,
          };
        }),
    );

    const updateCustomAssessValue = (fieldID: number, val: string) => {
        let tmp = [...customAssessValues];
        const i = tmp.findIndex(x => x.field_id === fieldID);
        if (i >= 0) {
            tmp[i].field_val = val;
        }
        setCustomAsseesValues(tmp);
    };

    useEffect(() => {
      let tmp = { ...formDataPassed,
        estimated_dozens: formDataPassed.spat_amount_dz ?? 0,
        estimated_pcs: formDataPassed.spat_amount_pcs ?? 0
       };
      setFormData(tmp);
    }, [formDataPassed]);

    useEffect(() => {
        setErrors(errorsData);
    }, [errorsData]);
    
    return (
        <div>
            {(isMyFarm && type == 'OYSTER') && (
                <div className='pb-17'>
                    <OysterLineProbTable
                    ref={tableRef}
                    lineID={lineData.id}
                    amountType={formData?.measurement_units}
                    totalAmount={formData?.measurement_units === 'pcs' ? formData.estimated_pcs ?? 0 : formData.estimated_dozens ?? 0}
                    />
                </div>
            )}
            {customAssessValues.map(x => (
            <div key={x.field_id} className='mb-17'>
                {x.input_type === 'select' ? (
                <>
                    <Dropdown
                    label={x.field_label}
                    options={x.options ?? []}
                    value={x.field_val}
                    onChange={v => updateCustomAssessValue(x.field_id, v)}
                    disabled={onlyView}
                    />
                    {(!x.options || x.options.length <= 0) && (
                    <Link to={'/custom-fields'}>
                        <span style={{ color: '#008ed1' }}>Set up field</span>
                    </Link>
                    )}
                </>
                ) : (
                <Input
                    type={x.data_type === 'number' ? 'number' : 'text'}
                    label={x.field_label}
                    value={x.field_val ?? ''}
                    onChange={e =>
                    updateCustomAssessValue(x.field_id, e.target.value)
                    }
                    unit={x.unit}
                    disabled={onlyView}
                />
                )}
            </div>
            ))}
            <CheckboxButton
                className='mb-17'
                label={translate(lang, 'Ready to harvest')}
                checked={formData.ready_to_harvest}
                onChange={e => updateForm('ready_to_harvest', e.target.checked)}
                disabled={onlyView}
            />
            <div className='assessment-modal__condition d-flex justify-content-between'>
                <div className={`mx-180 ${errors?.min ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size min')} (mm)`}
                    value={formData.size_min?.toString() ?? ''}
                    onChange={e => updateForm('size_min', e.target.value)}
                    required={true}
                  />
                  {errors?.min && (
                    <div className='invalid-feedback'>{errors.min}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors?.max ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size max')} (mm)`}
                    value={formData.size_max?.toString() ?? ''}
                    onChange={e => updateForm('size_max', e.target.value)}
                    required={true}
                  />
                  {errors?.max && (
                    <div className='invalid-feedback'>{errors.max}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors?.avg ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size avg')} (mm)`}
                    value={formData.size_avg?.toString() ?? ''}
                    onChange={e => updateForm('size_avg', e.target.value)}
                    required={true}
                  />
                  {errors?.avg && (
                    <div className='invalid-feedback'>{errors.avg}</div>
                  )}
                </div>
              </div>
        </div>
    );
}

export default InstantOysterAssesment;