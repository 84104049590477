import React, { useState, useEffect } from 'react';
import { translate } from '../../../lib/lang.helper';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../store/ui/ui.selector';
import {
    CheckboxButton,
    Input,
    Dropdown
} from '../../shared';
import {
    SelectAssessFields,
  } from '../../../store/extra/extra.selector';

import { ICustomValue } from '../../../entities/automation.entities';
import { Link } from 'react-router-dom';
import { IMusselLineResource } from '../../../entities/farms.entities';
import moment from 'moment';

interface Props {
  lineData: IMusselLineResource;
  data?: any;
  onlyView?: boolean;
  updateForm: (key: keyof IFormData, value: any) => void;
  formDataPassed:any;
  errorsData: any;
}


interface IFormData {
  season_name: string;
  spat_storage_id: number | null;
  spat_amount: number | null;
  planned_date_seed: number;
  planned_date_harvest: number;
  line_length: number | null;
  spat_size: number;
  spat_size_max: number | null;
  density: number;
  density_max: number | null;
  density_unit: 'spat per m' | 'kgs per m';
  drop: number;
  submersion: number;
  spacing: number;
  comment: string | null;
  budget_unit_amount: number | null;
  budget_unit_price: number | null;
  images: any[];
  cost?: number | null;
  direction: string | null;
  parent_id?: number | null;
  picked_inventories?: any[] | null;
  custom_values?: ICustomValue[];
  size_min: number | null;
  size_max: number | null;
  size_avg: number | null;
  btm_size_min?: number | null;
  btm_size_max?: number | null;
  btm_size_avg?: number | null;
  assess_bottom?: boolean | null;
  ready_to_harvest?: boolean;
}

const defaultFormData: IFormData = {
  season_name: '',
  spat_storage_id: null,
  spat_amount: null,
  planned_date_seed: moment().toDate().getTime(),
  planned_date_harvest: moment().toDate().getTime(),
  line_length: 0,
  spat_size: 0,
  spat_size_max: null,
  density: 0,
  density_max: null,
  density_unit: 'spat per m',
  drop: 0,
  submersion: 0,
  spacing: 0,
  direction: null,
  comment: null,
  budget_unit_amount: null,
  budget_unit_price: null,
  images: [],
  size_min: null,
  size_max: null,
  size_avg: null,
  assess_bottom: false,
  ready_to_harvest: false,
};



const InstantMusselAssesment = ({ updateForm, lineData, data, onlyView, formDataPassed, errorsData} : Props) => {
    const [formData, setFormData] = useState<IFormData>(defaultFormData);
    const lang = useSelector(selectLang);
    const [assessBottom, setAssessBottom] = useState(!!data?.btm_size_avg);
    const customAssesFields = SelectAssessFields(lineData.farm_id);
    const [customAssessValues, setCustomAsseesValues] = useState(
        customAssesFields.map(x => {
          const d =
            data && data.custom_values
              ? data.custom_values.find((y: ICustomValue) => y.field_id === x.id)
              : null;
          return {
            input_type: x.input_type,
            field_label: x.name,
            options: x.options
              ?.split(',')
              .map(t => ({ id: t, label: t, value: t })),
            field_id: x.id,
            field_val: d?.field_val ?? '',
            data_type: x.data_type,
            unit: x.unit,
          };
        }),
    );
    const [errors, setErrors] = useState<{
        min: string | null;
        max: string | null;
        avg: string | null;
        }>({
        min: errorsData?.size_avg ?? null,
        max: errorsData?.size_max ?? null,
        avg: errorsData?.size_avg ?? null,
    });
    const updateCustomAssessValue = (fieldID: number, val: string) => {
        let tmp = [...customAssessValues];
        const i = tmp.findIndex(x => x.field_id === fieldID);
        if (i >= 0) {
            tmp[i].field_val = val;
        }
        setCustomAsseesValues(tmp);
    };

    useEffect(() => {
      let tmp = { ...formDataPassed,
        estimated_dozens: formDataPassed.spat_amount_dz ?? 0
      };
      setFormData(tmp);
    }, [formDataPassed]);
    
    useEffect(() => {
        setErrors(errorsData);
    }, [errorsData]);

    return (
        <div>
            <div className='assessment-modal__condition d-flex justify-content-between'>
                <div className={`mx-180 ${errors?.min ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(
                      lang,
                      assessBottom ? 'Top size min' : 'Size min',
                    )} (mm)`}
                    value={formData.size_min?.toString() ?? ''}
                    onChange={e => updateForm('size_min', e.target.value)}
                    required={true}
                    disabled={onlyView}
                  />
                  {errors?.min && (
                    <div className='invalid-feedback'>{errors.min}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors?.max ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(
                      lang,
                      assessBottom ? 'Top size max' : 'Size max',
                    )} (mm)`}
                    value={formData.size_max?.toString() ?? ''}
                    onChange={e => updateForm('size_max', e.target.value)}
                    required={true}
                    disabled={onlyView}
                  />
                  {errors?.max && (
                    <div className='invalid-feedback'>{errors.max}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors?.avg ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(
                      lang,
                      assessBottom ? 'Top size average' : 'Size average',
                    )} (mm)`}
                    value={formData.size_avg?.toString() ?? ''}
                    onChange={e => updateForm('size_avg', e.target.value)}
                    required={true}
                    disabled={onlyView}
                  />
                  {errors?.avg && (
                    <div className='invalid-feedback'>{errors.avg}</div>
                  )}
                </div>
              </div>
              <div className='mb-17'>
                <CheckboxButton
                  label={translate(lang, 'Measure top/bottom separately')}
                  checked={assessBottom}
                  onChange={e => { setAssessBottom(e.target.checked); updateForm('assess_bottom', e.target.checked) }}
                />
              </div>
              {assessBottom && (
                <div className='assessment-modal__condition d-flex justify-content-between'>
                  <div className='mx-180'>
                    <Input
                      type='number'
                      label={`${translate(lang, 'Bottom size min')} (mm)`}
                      value={formData.btm_size_min?.toString() ?? ''}
                      onChange={e => updateForm('btm_size_min', e.target.value)}
                      required={true}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='mx-180'>
                    <Input
                      type='number'
                      label={`${translate(lang, 'Bottom size max')} (mm)`}
                      value={formData.btm_size_max?.toString() ?? ''}
                      onChange={e => updateForm('btm_size_max', e.target.value)}
                      required={true}
                      disabled={onlyView}
                    />
                  </div>
                  <div className='mx-180'>
                    <Input
                      type='number'
                      label={`${translate(lang, 'Bottom size average')} (mm)`}
                      value={formData.btm_size_avg?.toString() ?? ''}
                      onChange={e => updateForm('btm_size_avg', e.target.value)}
                      required={true}
                      disabled={onlyView}
                    />
                  </div>
                </div>
              )}
              <CheckboxButton
                  className='mb-17'
                  label={translate(lang, 'Ready to harvest')}
                  checked={formData.ready_to_harvest}
                  onChange={e => updateForm('ready_to_harvest', e.target.checked)}
                  disabled={onlyView}
              />
              {customAssessValues.map(x => (
                <div key={x.field_id} className='mb-17'>
                    {x.input_type === 'select' ? (
                    <>
                        <Dropdown
                        label={x.field_label}
                        options={x.options ?? []}
                        value={x.field_val}
                        onChange={v => updateCustomAssessValue(x.field_id, v)}
                        disabled={onlyView}
                        />
                        {(!x.options || x.options.length <= 0) && (
                        <Link to={'/custom-fields'}>
                            <span style={{ color: '#008ed1' }}>Set up field</span>
                        </Link>
                        )}
                    </>
                    ) : (
                    <Input
                        type={x.data_type === 'number' ? 'number' : 'text'}
                        label={x.field_label}
                        value={x.field_val ?? ''}
                        onChange={e =>
                        updateCustomAssessValue(x.field_id, e.target.value)
                        }
                        unit={x.unit}
                        disabled={onlyView}
                    />
                    )}
                </div>
                ))}
        </div>
    );
}

export default InstantMusselAssesment;