import { forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Table, Button, Alert } from 'antd';
import { translate } from '../../lib/lang.helper';
import { Input } from '../shared';
import { formatNumber } from '../../entities/util-functions';
import { selectLang } from '../../store/ui/ui.selector';

interface IRow {
  id: string | number;
  name: string;
  amount: number | undefined;
}

interface Props {
  lineID: number;
  onInsert?: (probabilities: Record<string, string>) => void;
  oysterFields: any[];
  onGrowWaste: any;
}

export interface OysterProbRef {
  checkIsValid(): string | true;
  runConfirm(): Promise<string | true>;
}

interface IFormData {
  estimated_dozens?: number;
}

const calcOnGrowWastePercent = (data: any, type: string, lineID: number) => {
  return data?.[lineID]?.[type] ?? 0;
};

const calcOysterPercent = (oyster: any, lineID: number) => {
  return oyster.percent ?? 0;
};

const OysterSampleCalculator = forwardRef<OysterProbRef, Props>(
  ({ lineID, onInsert, oysterFields, onGrowWaste }, ref) => {
    const lang = useSelector(selectLang);

    const defaultValues: IRow[] = [
      {
        id: 'Grown ons',
        name: translate(lang, 'Grown ons'),
        amount: calcOnGrowWastePercent(onGrowWaste, 'grow', lineID),
      },
      {
        id: 'Waste',
        name: translate(lang, 'Waste'),
        amount: calcOnGrowWastePercent(onGrowWaste, 'waste', lineID),
      },
      ...oysterFields.map(x => ({
        id: x.id,
        name: x.name,
        amount: calcOysterPercent(x, lineID),
      })),
    ];

    const [modalData, setModalData] = useState<IRow[]>(defaultValues);
    const [formData, setFormData] = useState<IFormData>({
      estimated_dozens: undefined,
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exceedsLimit, setExceedsLimit] = useState(false);

    const updateForm = (field: keyof IFormData, value: string) => {
      setFormData(prev => ({
        ...prev,
        [field]: value ? parseFloat(value) : undefined,
      }));
    };

    const updateAmount = (id: string | number, value: string) => {
      const newData = [...modalData];
      const index = newData.findIndex(row => row.id === id);
      newData[index].amount = value ? parseFloat(value) : undefined;
      setModalData(newData);

      const totalOysters = formData.estimated_dozens || 0;
      const sumOfAllAmounts = newData.reduce(
        (acc, x) => acc + (x.amount || 0),
        0,
      );
      setExceedsLimit(totalOysters > 0 && sumOfAllAmounts > totalOysters);
    };

    const checkIsValid = () => {
      const totalOysters = formData.estimated_dozens || 0;
      const sumOfAllAmounts = modalData.reduce(
        (acc, x) => acc + (x.amount || 0),
        0,
      );

      if (sumOfAllAmounts > totalOysters) {
        return translate(
          lang,
          'Sum of amounts exceeds the estimated total (over 100%)',
        );
      }
      return true;
    };

    const runConfirm = async () => {
      const isValid = checkIsValid();
      if (isValid !== true) return isValid;
      return true;
    };

    useImperativeHandle(ref, () => ({
      checkIsValid,
      runConfirm,
    }));

    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);

    const handleInsert = () => {
      const probabilities = modalData.reduce((acc, row) => {
        const probability =
          totalOysters > 0 && row.amount
            ? ((row.amount / totalOysters) * 100).toFixed(1)
            : '0';
        acc[row.id.toString()] = probability;
        return acc;
      }, {} as Record<string, string>);

      if (onInsert) {
        onInsert(probabilities);
      }
      setIsModalVisible(false);
    };

    const totalOysters = formData.estimated_dozens || 0;
    const sumOfAllAmounts = modalData.reduce(
      (acc, x) => acc + (x.amount || 0),
      0,
    );

    const columns: any = [
      {
        title: translate(lang, 'Name'),
        key: 'name',
        render: (x: IRow) => x.name,
        width: 180,
      },
      {
        title: translate(lang, 'Amount'),
        key: 'amount',
        render: (x: IRow) => (
          <Input
            label=''
            placeholder={translate(lang, 'Amount')}
            type='number'
            value={x.amount?.toString() ?? ''}
            onChange={e => updateAmount(x.id, e.target.value)}
          />
        ),
        width: 120,
      },
      {
        title: () => {
          const sumProb =
            totalOysters > 0
              ? ((sumOfAllAmounts / totalOysters) * 100).toFixed(1) + '%'
              : '0%';
          return translate(lang, 'Probability') + ` (${sumProb})`;
        },
        key: 'probability',
        render: (x: IRow) => {
          if (!x.amount || totalOysters === 0) return '0%';
          const percent = ((x.amount / totalOysters) * 100).toFixed(1);
          return percent + '%';
        },
        width: 140,
      },
    ];

    return (
      <>
        <Button
          onClick={showModal}
          type='primary'
          style={{ marginBottom: '20px' }}
        >
          Sample Calculator
        </Button>

        <Modal
          title='Sample Calculator'
          visible={isModalVisible}
          onCancel={handleCancel}
          style={{ marginTop: '10%' }}
          footer={[
            <Button key='cancel' onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key='insert' type='primary' onClick={handleInsert}>
              Insert
            </Button>,
          ]}
          className='calcuate-modal'
        >
          {exceedsLimit && (
            <Alert
              message={translate(lang, 'Total probability exceeds 100%!')}
              type='warning'
              showIcon
              style={{ marginBottom: '15px' }}
            />
          )}

          <div style={{ marginBottom: '1rem' }}>
            <Input
              label={translate(lang, 'Estimated Oysters')}
              type='number'
              value={formData.estimated_dozens?.toString() ?? ''}
              onChange={e => updateForm('estimated_dozens', e.target.value)}
              unit='amount'
            />
          </div>

          <Table
            rowKey='id'
            className='table table--units table--small sample-calc-table'
            dataSource={modalData}
            columns={columns}
            pagination={false}
          />
        </Modal>
      </>
    );
  },
);

export default OysterSampleCalculator;
