import { useState } from 'react';
import { IFarmResource } from '../../entities/farms.entities';
import FarmStatusModal from '../farm-modals/FarmStatusModal';
import { Button } from '../shared';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  farm: IFarmResource;
}

const FarmStatusButton = ({ farm }: Props) => {
  const lang = useSelector(selectLang);
  const [visible, setVisible] = useState(false);

  const handleClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setVisible(true);
  };

  return (
    <>
      <Button
        width='small'
        size={0}
        type='fill'
        color={farm.farm_status?.status !== false ? 'blue' : 'red'}
        onClick={handleClick}
      >
        {translate(lang, farm.farm_status?.status !== false ? 'Open' : 'Closed')}
      </Button>
      {visible && (
        <FarmStatusModal farm={farm} onClose={() => setVisible(false)} />
      )}
    </>
  );
};

export default FarmStatusButton;
