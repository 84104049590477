import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useWidth } from '../../util/useWidth';
import { Spinner } from '../../components/shared';
import CatchSpatModal from '../../components/farm-modals/CatchSpatModal';
import ExtraSeedingsTable from '../../components/lines/ExtraSeedingsTable';
import HarvestsTable from '../../components/lines/HarvestsTable';
import PendingDataTable from '../../components/lines/PendingDataTable';
import GrowerHarvestModal from '../../components/farm-modals/GrowerHarvestModal';
import GrowerSeedModal from '../../components/farm-modals/GrowerSeedModal';
import SeedingModal from '../../components/farm-modals/SeedingModal';
import MaintenanceModal from '../../components/farm-modals/MaintenanceModal';
import FloatManageModal from '../../components/farm-modals/FloatManageModal';
import AssessmentModal from '../../components/farm-modals/AssessmentModal';
import HarvestCompleteModal from '../../components/farm-modals/HarvestCompleteModal';
import TimelineView from '../../components/lines/TimelineView';
import { sendSingleRequest } from '../../apis';
import {
  IFarmWeatherResource,
  ILineResource,
  IMusselLineDetail,
  IOysterLineDetail,
} from '../../entities/farms.entities';
import { showFeedback } from '../../store/ui/ui.actions';
import LineFormModal from '../../components/farm-modals/LineFormModal';
import GrowerMaintenanceModal from '../../components/farm-modals/GrowerMaintenanceModal';
import LineTemplateDesktop from './LineTemplateDesktop';
import LineTemplateMobile from './LineTemplateMobile';
import MaintenanceTable from '../../components/lines/MaintenanceTable';
import { IMusselCycle } from '../../entities/growing.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectFarmsByFarmID,
  SelectIsGrower,
} from '../../store/extra/extra.selector';
import './styles.scss';

const FarmLine = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const width = useWidth();
  const params = useParams<{ idFarm: string; idLine: string }>();
  const lang = useSelector(selectLang);
  const currentFarm = SelectFarmsByFarmID(+params.idFarm).find(
    x => x.id === +params.idFarm,
  );
  const isGrower = SelectIsGrower(+params.idFarm);

  const [lineData, setLineData] = useState<
    IMusselLineDetail | IOysterLineDetail
  >();
  const [loading, setLoading] = useState(false);
  const musselLine = lineData as IMusselLineDetail;

  const maxIdx =
    (lineData?.cycles.length ?? 0) - (lineData?.growing_cycle ? 1 : 0);

  const [csIndex, setCsIndex] = useState(maxIdx);
  const isNextEnable = csIndex < maxIdx;
  const isPrevEnable = csIndex > 0;
  const curCycle =
    lineData?.cycles && lineData.cycles.length > csIndex
      ? lineData.cycles[csIndex]
      : null;
  const mainSeedResource = (curCycle?.seedings as any)?.find(
    (x: any) => !x.parent_id,
  );
  const musselCycle = musselLine?.cycles[csIndex];

  const handleClickPrevGroup = () => {
    if (csIndex <= 0) return;
    setCsIndex(csIndex - 1);
  };
  const handleClickNextGroup = () => {
    if (csIndex >= maxIdx) return;
    setCsIndex(csIndex + 1);
  };

  const [editLineData, setEditLineData] = useState<ILineResource>();
  const [seedModal, setSeedModal] = useState(false);
  const [visibleAssess, setVisibleAssess] = useState(false);
  const [editSeedModal, setEditSeedModal] = useState(false);
  const [maintenanceModal, setMaintenanceModal] = useState(false);
  const [visibleInventories, setVisibleInventories] = useState(false);
  const [visibleExtraSeed, setVisibleExtraSeed] = useState(false);
  const [visibleCatchSpat, setVisibleCatchSpat] = useState(false);
  const [visibleHarvestModal, setVisibleHarvestModal] = useState(false);
  const [visibleTimeline, setVisibleTimeline] = useState(0);
  const [timelines, setTimelines] = useState<any[]>();
  const [weathers, setWeathers] = useState<IFarmWeatherResource[]>();

  const toggleVisibleTimeline = async () => {
    if (!curCycle) return;
    if (visibleTimeline) {
      setTimelines(undefined);
      setVisibleTimeline(0);
    } else {
      setVisibleTimeline(-1);
      const resp = await sendSingleRequest(
        { id: curCycle.main_seed.id, with_history: true },
        'GET',
        'api/farm/line/seeding',
        true,
      );
      if (resp.status) {
        const {
          seedings,
          assessments,
          maintenances,
          inventory_seeds,
          harvests,
          seed_manages,
        } = resp.data;
        let min_date = -1;
        for (let x of seedings) {
          if (min_date === -1 || min_date > x.planned_date_seed)
            min_date = x.planned_date_seed;
        }
        for (let x of assessments) {
          if (min_date === -1 || min_date > x.assessment_date)
            min_date = x.assessment_date;
        }
        for (let x of maintenances) {
          if (min_date === -1 || min_date > x.maintain_date)
            min_date = x.maintain_date;
        }
        for (let x of inventory_seeds) {
          if (min_date === -1 || min_date > x.manage_date)
            min_date = x.manage_date;
        }
        for (let x of harvests) {
          if (min_date === -1 || min_date > x.complete_date)
            min_date = x.complete_date;
        }
        for (let x of seed_manages) {
          if (min_date === -1 || min_date > x.manage_date)
            min_date = x.manage_date;
        }
        min_date = min_date * 1000;
        const wsp = await sendSingleRequest(
          { min_date, farm_id: params.idFarm },
          'GET',
          'api/farm/weather',
          true,
        );
        const lsp = await sendSingleRequest(
          {},
          'GET',
          `api/farm/line/tag-logs/${params.idLine}`,
          true,
        );
        let logs: any[] = [];
        if (lsp.status) {
          for (let x of lsp.data) {
            if (!x.is_on) {
              logs.push(
                {
                  id: x.id,
                  tag: x.tag,
                  is_on: true,
                  logged_date: x.created_at,
                  user: x.user,
                },
                {
                  id: x.id,
                  tag: x.tag,
                  is_on: false,
                  logged_date: x.updated_at,
                  user: x.user,
                },
              );
            } else {
              logs.push({
                id: x.id,
                tag: x.tag,
                is_on: true,
                logged_date: x.created_at,
                user: x.user,
              });
            }
          }
        }
        let planned: any[] = [];
        for (let seed of seedings) {
          const dates = seed.planned_date_history ?? [];
          for (let i = 0; i < dates.length - 1; i++) {
            if (dates[i] !== dates[i + 1]) {
              planned.push({
                id: `${seed.id} - ${i}`,
                old_date: dates[i],
                new_date: dates[i + 1],
              });
            }
          }
        }
        setTimelines([
          ...seedings.map((x: any) => ({ type: 'SEEDING', data: x })),
          ...assessments.map((x: any) => ({
            type: 'ASSESSMENT',
            data: x,
          })),
          ...maintenances.map((x: any) => ({
            type: 'MAINTENANCE',
            data: x,
          })),
          ...inventory_seeds.map((x: any) => ({
            type: 'INVENTORY_SEED',
            data: x,
          })),
          ...harvests.map((x: any) => ({ type: 'HARVEST', data: x })),
          ...seed_manages.map((x: any) => ({ type: 'SEED_MANAGE', data: x })),
          ...logs.map((x: any) => ({ type: 'TAG_LOG', data: x })),
          ...planned.map((x: any) => ({ type: 'HARVEST_PLANNED', data: x })),
        ]);
        if (wsp.status) {
          setWeathers(wsp.data);
        }
        setVisibleTimeline(1);
      } else {
        setVisibleTimeline(0);
        alert(resp.data?.message);
        return;
      }
    }
  };

  const loadData = useCallback(
    async (isInternal = false) => {
      if (!isInternal) {
        setLoading(true);
      }
      const response = await sendSingleRequest(
        { with_harvest_spats: true },
        'GET',
        `api/farm/line/lines/${params.idLine}`,
        true,
      );
      if (response.status) {
        setLineData(response.data);
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, 'Failed to load information'),
          }),
        );
        history.replace(`/farms/${params.idFarm}`);
      }
      if (!isInternal) {
        setLoading(false);
      }
    },
    [params.idLine, params.idFarm],
  );

  useEffect(() => setCsIndex(maxIdx), [maxIdx]);

  useEffect(() => {
    setTimelines(undefined);
    setVisibleTimeline(0);
  }, [curCycle]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!lineData || !currentFarm) {
    return (
      <div className='h-calc-80 bg-secondary'>
        <div className='container pos-relative'>
          <Spinner position='global' />
        </div>
      </div>
    );
  }
  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        <div className='farms__harvest pb-36'>
          {width <= 768 ? (
            <LineTemplateMobile
              type={currentFarm.type}
              lineData={lineData}
              cyclePart={curCycle}
              isNextEnabled={isNextEnable}
              isPrevEnabled={isPrevEnable}
              onClickPrevGroup={handleClickPrevGroup}
              onClickNextGroup={handleClickNextGroup}
              showEditLineModal={() => setEditLineData(lineData)}
              showEditSeedModal={() => setEditSeedModal(true)}
              onSeedingClick={() => setSeedModal(true)}
              onAssessmentClick={() => setVisibleAssess(true)}
              onCatchSpatClick={() => setVisibleCatchSpat(true)}
              onHarvestClick={() => setVisibleHarvestModal(true)}
              onMaintenanceClick={() => setMaintenanceModal(true)}
              onFloatingManageClick={() => setVisibleInventories(true)}
              onExtraSeedClick={() => setVisibleExtraSeed(true)}
              checkedHistoryView={visibleTimeline}
              onHistoryViewClick={toggleVisibleTimeline}
            />
          ) : (
            <LineTemplateDesktop
              type={currentFarm.type}
              loadLine={loadData}
              lineData={lineData}
              cyclePart={curCycle}
              isNextEnabled={isNextEnable}
              isPrevEnabled={isPrevEnable}
              onClickPrevGroup={handleClickPrevGroup}
              onClickNextGroup={handleClickNextGroup}
              showEditLineModal={() => setEditLineData(lineData)}
              showEditSeedModal={() => setEditSeedModal(true)}
              onSeedingClick={() => setSeedModal(true)}
              onAssessmentClick={() => setVisibleAssess(true)}
              onCatchSpatClick={() => setVisibleCatchSpat(true)}
              onHarvestClick={() => setVisibleHarvestModal(true)}
              onMaintenanceClick={() => setMaintenanceModal(true)}
              onFloatingManageClick={() => setVisibleInventories(true)}
              onExtraSeedClick={() => setVisibleExtraSeed(true)}
              checkedHistoryView={visibleTimeline}
              onHistoryViewClick={toggleVisibleTimeline}
            />
          )}
          {visibleTimeline === 1 &&
            (timelines ? (
              <TimelineView
                type={currentFarm.type}
                dataItems={timelines}
                isGrowing={curCycle?.main_seed.is_growing}
                weathers={weathers}
              />
            ) : (
              <div className='mt-17 mb-17'>
                <Spinner />
              </div>
            ))}
          {(currentFarm.type === 'MUSSEL' || currentFarm.type === 'OYSTER' ) && !isGrower && (
            <PendingDataTable lineData={musselLine} loadLine={loadData} />
          )}
          <div className='w-100'>
            {curCycle ? (
              <MaintenanceTable
                type={currentFarm.type}
                assessments={curCycle.assessments}
                maintenances={curCycle.maintenances}
                floats={(curCycle as IMusselCycle).inventory_seeds}
                seed_manages={(curCycle as IMusselCycle).seed_manages}
                lineData={lineData}
                loadLine={loadData}
              />
            ) : (
              lineData.maintenances && (
                <MaintenanceTable
                  type={currentFarm.type}
                  maintenances={lineData.maintenances}
                  lineData={lineData}
                  loadLine={loadData}
                />
              )
            )}
          </div>
          <div className='w-100 mt-10'>
            {(curCycle && curCycle.main_seed.is_growing) && (
              <ExtraSeedingsTable
                loadLine={loadData}
                lineData={lineData}
                cyclePart={curCycle}
                type={currentFarm.type}
              />
            )}
          </div>
          {!!curCycle?.harvests && curCycle.harvests.length > 0 && (
            <div className='w-100 mt-10'>
              <HarvestsTable
                harvests={curCycle.harvests}
                simple={true}
                type={currentFarm.type}
                onUpdateRow={loadData}
                farmID={currentFarm.id}
              />
            </div>
          )}
        </div>
        {loading && <Spinner position='global' />}
      </div>
      {seedModal && (
        <>
          {isGrower ? (
            <GrowerSeedModal
              type={currentFarm.type}
              visible={seedModal}
              onClose={() => setSeedModal(false)}
              lineData={lineData}
              onConfirm={() => {
                setSeedModal(false);
                loadData();
              }}
            />
          ) : (
            <SeedingModal
              type={currentFarm.type}
              visible={seedModal}
              title='Seed the line'
              lineData={lineData}
              onCancel={() => setSeedModal(false)}
              onConfirm={() => {
                setSeedModal(false);
                loadData();
              }}
            />
          )}
        </>
      )}
      {visibleAssess && curCycle && (
        <AssessmentModal
          type={currentFarm.type}
          title='Add assessment'
          visible={visibleAssess}
          onCancel={() => setVisibleAssess(false)}
          lineData={lineData}
          onConfirm={() => {
            setVisibleAssess(false);
            loadData();
          }}
        />
      )}
      {!!editLineData && (
        <LineFormModal
          visible={true}
          lineData={editLineData}
          onClose={u => {
            setEditLineData(undefined);
            if (u === true) loadData();
          }}
          title='Edit line details'
          farmData={currentFarm}
        />
      )}
      {editSeedModal && curCycle && (
        <>
          {isGrower ? (
            <GrowerSeedModal
              type={currentFarm.type}
              lineData={lineData}
              seedData={mainSeedResource}
              visible={editSeedModal}
              onClose={() => setEditSeedModal(false)}
              onConfirm={() => {
                setEditSeedModal(false);
                loadData();
              }}
            />
          ) : (
            <SeedingModal
              type={currentFarm.type}
              visible={editSeedModal}
              title='Update seeding data'
              lineData={lineData}
              onCancel={() => setEditSeedModal(false)}
              data={mainSeedResource}
              updateID={curCycle.main_seed.id}
              onConfirm={() => {
                setEditSeedModal(false);
                loadData();
              }}
            />
          )}
        </>
      )}
      {visibleHarvestModal && (
        <>
          {isGrower ? (
            <GrowerHarvestModal
              type={currentFarm.type}
              visible={true}
              lineData={lineData}
              onClose={() => setVisibleHarvestModal(false)}
              onConfirm={() => {
                setVisibleHarvestModal(false);
                loadData();
              }}
            />
          ) : (
            <HarvestCompleteModal
              type={currentFarm.type}
              visible={true}
              title='Harvest Complete'
              onCancel={() => setVisibleHarvestModal(false)}
              onConfirm={d => {
                setVisibleHarvestModal(false);
                if (d?.data?.is_final === false || d?.data?.is_final === 0) {
                  history.push(`/spat-storage?type=${currentFarm.type}`);
                } else {
                  loadData();
                }
              }}
              lineData={lineData}
            />
          )}
        </>
      )}
      {visibleCatchSpat && (
        <CatchSpatModal
          title='Catch Spat'
          visible={true}
          onCancel={() => setVisibleCatchSpat(false)}
          lineData={lineData}
          onConfirm={() => {
            setVisibleCatchSpat(false);
            loadData();
          }}
          type={currentFarm.type}
        />
      )}
      {maintenanceModal && (
        <>
          {isGrower ? (
            <GrowerMaintenanceModal
              visible={true}
              onClose={() => setMaintenanceModal(false)}
              onConfirm={() => {
                setMaintenanceModal(false);
                loadData();
              }}
              lineData={musselLine}
            />
          ) : (
            <MaintenanceModal
              visible={true}
              title='Add maintenance'
              onCancel={() => setMaintenanceModal(false)}
              onConfirm={() => {
                setMaintenanceModal(false);
                loadData();
              }}
              lineData={lineData}
            />
          )}
        </>
      )}
      {visibleInventories && (
        <FloatManageModal
          visible={visibleInventories}
          onCancel={() => setVisibleInventories(false)}
          title={`Inventory Management`}
          onConfirm={() => {
            setVisibleInventories(false);
            loadData();
          }}
          lineData={lineData}
          manageCycle={currentFarm.type === 'MUSSEL' ? musselCycle : undefined}
        />
      )}
      {visibleExtraSeed && lineData.growing_cycle && (
        <>
          {isGrower ? (
            <GrowerSeedModal
              type={currentFarm.type}
              lineData={lineData}
              visible={visibleExtraSeed}
              onClose={() => setVisibleExtraSeed(false)}
              onConfirm={() => {
                setVisibleExtraSeed(false);
                loadData();
              }}
            />
          ) : (
            <SeedingModal
              type={currentFarm.type}
              visible={visibleExtraSeed}
              title='Extra seed the line'
              onCancel={() => setVisibleExtraSeed(false)}
              onConfirm={() => {
                setVisibleExtraSeed(false);
                loadData();
              }}
              lineData={lineData}
              parentID={lineData.growing_cycle.main_seed.id}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FarmLine;
