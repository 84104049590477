import { compose, withProps } from 'recompose';
import { useState, useRef } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  withScriptjs,
  withGoogleMap,
  Polygon,
  Polyline,
  OverlayView,
} from 'react-google-maps';
import Spinner from '../spinner/Spinner';
import mapPin from '../../../images/map-pin.svg';
import { ILocation, TBusinessType } from '../../../entities/general.entities';
import warningIcon from '../../../images/warning-icon.png';
import { LINE_COLORS } from '../../../lib/constant';
import './styles.scss';
import DottedLine from './DottedLine';


const emptyColor = LINE_COLORS.EMPTY;

interface IMarker {
  content?: any;
  data?: any;
  location: ILocation;
  bounds?: ILocation[] | null;
  points?: ILocation[] | null;
  tracks?: ILocation[] | null;
  color?: string;
  onClick?: (x: any) => void;
  onMouseOver?: (x: ILocation) => void;
  onMouseOut?: (x: ILocation) => void;
  urgent?: ILocation | null;
  direction?: string | null | undefined;
  type?: TBusinessType;
  lineData?: any;
  totalLineLength?: number;
}

interface IPopup {
  position: ILocation;
  onClose: () => void;
  view: any;
}

interface Props {
  center?: ILocation;
  zoom?: number;
  markers: IMarker[];
  mapTypeId?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain';
  popup?: IPopup;
}

const defaultCenter = {
  lat: -41.0917585,
  lng: 173.828391,
};

const defaultZoom = 12;

const Loading = () => (
  <div className='mt-20'>
    <Spinner />
  </div>
);

declare namespace google {
  export const maps: any;
}

const MarkersMap = compose<Props, Props>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    loadingElement: <Loading />,
    containerElement: (
      <div style={{ width: '100%', height: `100%`, position: 'absolute' }} />
    ),
    mapElement: (
      <div style={{ width: '100%', height: `100%`, position: 'relative' }} />
    ),
  }),
  withScriptjs,
  withGoogleMap,
)(({ zoom, center, markers, mapTypeId, popup }) => {

  const mapRef = useRef<any>(null);
  const [zoomed, setZoomed] = useState<number|undefined>(zoom);
  const [gBounds, setGBounds] = useState<any | null>(null);

  const isShowDottedLine = (item: IMarker) => {
    if (!zoomed || zoomed <= 5 || !gBounds) {
      return false;
    }
    
    if (!item.points || item.points.length < 2) {
      return false;
    }

    return true;
  };

  return (
    <GoogleMap
      ref={mapRef}
      options={{
        minZoom: 3,
        maxZoom: 30,
      }}
      mapTypeId={mapTypeId}
      defaultZoom={zoom ?? defaultZoom}
      defaultCenter={center ?? defaultCenter}
      center={center ?? defaultCenter}
      zoom={zoom ?? defaultZoom}
      onClick={e => popup?.onClose()}
      onZoomChanged={() => {
        const currentZoom = mapRef.current?.getZoom();
        if (currentZoom !== undefined) {
          setZoomed(currentZoom);
        }
      }}
      onBoundsChanged={() => {
        const newBounds = mapRef.current?.getBounds();
        if (newBounds) {
          setGBounds(newBounds);
        }
      }}
    >
      {!!popup && <InfoWindow position={popup.position}>{popup.view}</InfoWindow>}
      {markers.map((item, idx) =>
        item.bounds && item.bounds.length > 2 ? (
          <div key={idx}>
            <Polygon
              path={item.bounds}
              options={{
                strokeColor: '#ff0000',
                strokeOpacity: 1,
                strokeWeight: 1,
                fillOpacity: 0,
              }}
              onClick={e => popup?.onClose()}
            />
            <InfoWindow position={item.location}>
              <div className='map__pin-label'>{item.content ?? ''}</div>
            </InfoWindow>
          </div>
        ) : item.points && item.points.length >= 2 ? (
          <div key={idx}>
            {(isShowDottedLine(item) ) && 
              <DottedLine item={item} direction={item.direction} bounds={gBounds}/>
            }
            <Polyline
              path={[item.points[0], item.points[1]]}
              options={{
                strokeColor: item.color ?? 'red',
                strokeOpacity: 1,
                strokeWeight: 3,
              }}
              onClick={item.onClick}
              onMouseOver={e =>
                item.onMouseOver
                  ? item.onMouseOver({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                  : undefined
              }
              onMouseOut={e =>
                item.onMouseOut
                  ? item.onMouseOut({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                  : undefined
              }
            />
            {item.points.length > 2 && (
              <Polyline
                path={[item.points[1], item.points[2]]}
                options={{
                  strokeColor: emptyColor,
                  strokeOpacity: 1,
                  strokeWeight: 3,
                }}
                onClick={item.onClick}
                onMouseOver={e =>
                  item.onMouseOver
                    ? item.onMouseOver({
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                      })
                    : undefined
                }
                onMouseOut={e =>
                  item.onMouseOut
                    ? item.onMouseOut({
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                      })
                    : undefined
                }
              />
            )}
            {!!item.content && (
              <OverlayView
                position={item.location}
                mapPaneName={OverlayView.OVERLAY_LAYER}
              >
                <div style={{ color: item.color, fontWeight: 'bold' }}>
                  {item.content}
                </div>
              </OverlayView>
            )}
            {!!item.urgent && (
              <OverlayView
                position={item.urgent}
                mapPaneName={OverlayView.OVERLAY_LAYER}
              >
                <div
                  style={{
                    position: 'absolute',
                    transform: 'translate(-50%, -100%)',
                  }}
                >
                  <img
                    style={{ width: '25px', height: '25px' }}
                    src={warningIcon}
                    alt='warning'
                  />
                </div>
              </OverlayView>
            )}
          </div>
        ) : !!item.tracks && item.tracks.length > 0 ? (
          <div key={idx}>
            <Polyline
              path={item.tracks}
              options={{
                strokeColor: '#ff0000',
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
            <InfoWindow position={item.location}>{item.content ?? ''}</InfoWindow>
          </div>
        ) : (
          <Marker
            key={idx}
            position={item.location}
            icon={{
              url: mapPin,
            }}
          >
            <InfoWindow>
              <div className='map__pin-label'>{item.content ?? ''}</div>
            </InfoWindow>
          </Marker>
        ),
      )}
    </GoogleMap>
  )
});

export default MarkersMap;