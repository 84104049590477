import { useMemo, useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { Marker } from 'react-google-maps';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import HarvestCompleteModal from '../../farm-modals/HarvestCompleteModal';
import { sendSingleRequest } from '../../../apis';
import { TBusinessType } from '../../../entities/general.entities';
import { componentTranslate, translate } from '../../../lib/lang.helper';
import { selectLang } from '../../../store/ui/ui.selector';

interface Item {
    points?: { lat: number; lng: number }[] | null;
    color?: string;
    basket_count?: number;
    total_seeded_basket_count?: number;
    seedings?: any;
    type?: TBusinessType;
    onMouseOver?: (position: { lat: number; lng: number }) => void;
    onMouseOut?: (position: { lat: number; lng: number }) => void;
    lineData?: any;
    totalLineLength?: number;
}

declare namespace google {
    export namespace maps {
      class Map {
        constructor(mapDiv: HTMLElement, opts?: any);
      }
      class LatLngBounds {
        constructor(southWest?: LatLng, northEast?: LatLng);
        extend(point: LatLng): void;
        getCenter(): LatLng;
        contains(latLng: LatLng): boolean;
      }
    
      class LatLng {
        constructor(lat: number, lng: number);
      }
      class AdvancedMarkerElement {
        constructor(opts?: any);
      }
    }
  }
  
function getStartingPoint(points: { lat: number; lng: number }[], direction: string|null|undefined) {
  const [point1, point2] = points;
  switch (direction) {
    case 'E':
      return point1.lng > point2.lng ? [point1, point2] : [point2, point1];
    case 'W':
      return point1.lng < point2.lng ? [point1, point2] : [point2, point1];
    case 'N':
      return point1.lat > point2.lat ? [point1, point2] : [point2, point1];
    case 'S':
      return point1.lat < point2.lat ? [point1, point2] : [point2, point1];
    default:
      return [point1, point2];
  }
}

const isInViewport = (
    position: { lat: number; lng: number },
    bounds: google.maps.LatLngBounds | null
  ) => {
    if (!bounds) return false;
    return bounds.contains(new google.maps.LatLng(position.lat, position.lng));
  };

const DottedLine = ({ item, direction, bounds }: { item: Item, direction: string|null|undefined, bounds: google.maps.LatLngBounds | null; }) => {
  const lang = useSelector(selectLang);
  const [activeInfo, setActiveInfo] = useState<{ lat: number; lng: number; seed: any } | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [lineData, setLineData] = useState<any>(null);
  const [loadingLineData, setLoadingLineData] = useState(false);
  const [seedID, setSeedID] = useState<number | null>(null);

  const markers = useMemo(() => {
    if (!item.points || item.points.length < 2 || item.seedings === undefined) return [];
    
    const [start, end] = getStartingPoint(item.points, direction);
    
    const markerCount = item.total_seeded_basket_count ? item.total_seeded_basket_count : item.totalLineLength ?? 0;

    const latStep = (end.lat - start.lat) / (markerCount - 1);
    const lngStep = (end.lng - start.lng) / (markerCount - 1);

    const points: { lat: number; lng: number; seed:any, type?:string }[] = [];
    if (item.seedings)
      var seedIndex = 0;
      item.seedings.map((seed: any, index:number) => {
        seed.type = item.type;
        points.push({
          lat: start.lat + (seedIndex * latStep),
          lng: start.lng + (seedIndex * lngStep),
          seed: seed,
        });
        const seedTemp = seed.basket_count ? seed.basket_count : seed.basket_count ?? 0
        seedIndex+= seedTemp - 1;
    });
    return points.filter(point => isInViewport(point, bounds));
  }, [item.points, bounds]);

  const handleHarvestLoad = async (e:any) => {
    setLoadingLineData(true);
    const response = await sendSingleRequest(
        {with_harvest_spats:true},
        'GET',
        `api/farm/line/lines/${item.lineData.id}`,
        true,
      );
    if (response.status) {
      setSeedID(activeInfo?.seed.id ?? null);
      setShowModal(true);
      setActiveInfo(null);
      setLineData(response.data);
      setLoadingLineData(false);
    } else {
      alert('Failed to load line data');
      setLoadingLineData(false);
    }
  }

  const prepareData = () => {
    const tmp = lineData;
    tmp.seed_id = [seedID];
    tmp.is_final = false;
    return tmp;
  }

  return (
    <>
      {markers.map((position, index) => {
        return (
          <Marker
            key={index}
            position={{ lat: position.lat, lng: position.lng }}
            icon={{
              url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="24px" height="24px">
                  <circle cx="12" cy="12" r="10" fill="white" />
                  <text x="12" y="16" text-anchor="middle" fill="black" font-size="12px" font-family="Arial" font-weight="bold">!</text>
                </svg>
              `)}`,
            }}
            onClick={() => setActiveInfo({ lat: position.lat, lng: position.lng, seed: position.seed })}
          />
        );
      })}

      {activeInfo && (
        <Modal
          visible={true}
          onCancel={(e) => setActiveInfo(null)}
          footer={null}
        >
          <div >
            <p><strong>{translate(lang, 'Seeding date')}:</strong> {defaultDateFormat(activeInfo.seed.planned_date_seed)}</p>
            <p><strong>{translate(lang, 'Spat ID')} {activeInfo.seed.spat_source}</strong></p>
            <p><strong>{translate(lang, 'Storage type')}: {activeInfo.seed.type}</strong></p>
            <p><strong>{item.type === 'OYSTER' ? translate(lang, 'Number of baskets') : translate(lang, 'Length')}: {activeInfo.seed.basket_count}</strong></p>
            <button className="rsp-btn button button--2 button--green button--small button--fill" disabled={loadingLineData} onClick={(e) => handleHarvestLoad(e)}>
              {loadingLineData ? 'Loading...' : 'Harvest'}
            </button>
          </div>
        </Modal>
      )}

      {(showModal && lineData) &&
        <>
        <HarvestCompleteModal 
          type={item.type ?? 'MUSSEL'}
          visible={showModal}
          title={''}
          lineData={lineData}
          data={prepareData()}
          onCancel={() => {setShowModal(false)}}
          onConfirm={() => {}}
          onlyView={false}
          warningMessage={''}
          lineChangeable={false}
        />
        </>
      }
    </>
  );
};

export default DottedLine;